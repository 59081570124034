import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Social from '../components/Social'

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteSocialQuery {
        global: markdownRemark(
          frontmatter: { templateKey: { eq: "global" } }
        ) {
          frontmatter {
            linkedInUrl
            twitterUrl
            facebookUrl
            instagramUrl
            crunchbaseUrl
          }
        }
      }
    `}
    render={data => <Social {...data.global.frontmatter} {...props} />}
  />
)
