import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Address from '../components/Address'

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteAddressQuery {
        site {
          siteMetadata {
            streetAddress
            addressLocality
            postalCode
          }
        }
      }
    `}
    render={data => <Address {...data.site.siteMetadata} {...props} />}
  />
)
