import styled from 'styled-components';

interface IProps {
  isSticky: boolean;
}

export const Banner = styled.header`
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  z-index: 2;
`;

export const Navbar = styled.div`
  height: var(--x5-space);
  display: flex;
  align-items: center;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    height: ${(props: IProps) =>
      props.isSticky ? `var(--x5-space);` : `var(--x7-space);`};
    transition: 0.25s height linear;
  }
`;

export const Logo = styled.div`
  flex-grow: 1;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    flex-grow: 0;
  }

  /* stylelint-disable */
  *[aria-hidden] {
    font-size: 0;
    overflow: hidden;
    visibility: hidden;
    position: absolute;
  }
  /* stylelint-enable */
`;

export const Navigation = styled.nav`
  display: none;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    display: block;
    flex: 1;
  }

  /* stylelint-disable */
  ul {
    display: flex;
    list-style: none;
    justify-content: center;
  }

  li {
    display: block;
    margin: 0 1.25rem;

    .link {
      font: 400 var(--type-6) var(--font-mark-pro-black);
      letter-spacing: 2px;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;

      &:hover {
        opacity: 1;
        text-decoration: none;
        color: var(--yellow);
        transition: color 0.5s linear;
      }
    }
  }
  /* stylelint-enable */
`;

// TODO Get style-component compositin nexting working

// const NavigationItems = `
//   ul {
//     display: flex;
//     list-style: none;
//     justify-content: center;
//   }
// `;

// export const NavigationItem = () => `
//   li {
//     display: block;
//     margin: 0 1.25rem;
//
//     /* stylelint-disable */
//     .link {
//       color: var(--white);
//       display: inline-block;
//       position: relative;
//       text-decoration: none;
//       text-transform: uppercase;
//     }
//     /* stylelint-enable */
//   }

// export const NavigationLink = styled(Nav)`
//   font: 400 var(--type-6) var(--font-mark-pro-black);
//   letter-spacing: 2px;
//   line-height: 1;
// `

export const Hamburger = styled.div`
  z-index: 3;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    /* stylelint-disable-next-line */
    margin-left: 34px; /* TODO improve this */
    position: relative;
  }
`;

export const HamburgerMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
