import styled from 'styled-components';
import { Section }  from '../Layout/styles';
import * as T from '../Typography';

interface IProps {
  backgroundColor: string;
  color: string;
  hasLine: boolean;
}

export const Column = styled(Section)`
  ${(props: IProps) => props.backgroundColor === '#E8FF00' && `
    background-color: ${props.backgroundColor};
    *::selection {
      background-color: var(--grey);
    }
  `}
`;

export const Label = styled(T.Label)`
  color: ${(props: IProps) => props.color};
  position: relative;
  margin-top: var(--x4-space);
`;

export const Headline = styled.div`
  margin-top: var(--x1-space);
  color: ${(props: IProps) => props.color};
`;

export const Columns = styled.div`
  margin-top: var(--x1-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: var(--x6-space);
  }
`;

export const Text = styled(T.SmallBodyCopy)`
  color: ${(props: IProps) => props.color};

  @media (min-width: ${props => props.theme.tabletBreak}) {
    ${(props: IProps) => (props.hasLine) && `
      padding-left: 30%;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: .75rem;
        left: 0;
        width: 20%;
        max-width: 86px;
        height: 1px;
        background-color: var(--black);
      }
    `}
  }

  /* stylelint-disable-next-line */
  p + p {
    margin-top: var(--x1-space);
  }
`;
