import styled from 'styled-components';
import { Section }  from '../Layout/styles';
import * as T  from '../Typography';

export const Footer = styled(Section)`
  background-color: var(--darkGrey);
`;

export const Row = styled.div`
  @media (min-width: ${props => props.theme.tabletBreak}) {
    &:not(:first-child) {
      margin-top: var(--x3-space);
    }
  }
`;

export const Strapline = styled.div`
  color: var(--white);
  font-family: var(--font-mark-pro-book);
`;

export const NavigationHeading = styled(T.Nav)`
  color: var(--white);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: var(--x3-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: 0;
    text-align: right;
  }
`;

export const FooterNavigation = styled.nav`
  /* stylelint-disable */
  ul {
    list-style: none;
  }

  li {
    display: block;
    margin-top: 1.5rem;

    @media (min-width: ${props => props.theme.tabletBreak}) {
      margin-top: 1rem;
      text-align: right;
    }

    .link {
      color: var(--grey);
      font: 400 var(--type-6) var(--font-mark-pro-medium);
      letter-spacing: 2px;
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
  /* stylelint-enable */
`;

export const Address = styled.div`
  margin-top: var(--x3-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: 0;
  }
`;

export const Social = styled.div`
  margin-top: var(--x3-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: 0;
  }
`;

export const Flex = styled.div`
  margin-top: 2rem;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const Box = styled.div`
  display: block;
`;

export const Copyright = styled(T.TinyBodyCopy)`
  color: var(--grey);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    text-align: right;
  }
`;

export const LegalNavigation = styled.div`
  /* stylelint-disable */
  ul {
    list-style: none;
  }

  li {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    .link {
      font: 400 var(--type-11) var(--font-mark-pro-book);
      text-decoration: underline;
      white-space: nowrap;
    }
  }
  /* stylelint-enable */
`;
