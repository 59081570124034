import styled from 'styled-components';
import * as bgZero1 from '../../images/bgZero1.svg';

export const Background = styled.div`
  position: fixed;
  height: 100vh;
  width: 10000px;
  background-image: url(${bgZero1});
  background-repeat: repeat-x;
  background-size: auto 75%;
  background-position: 0 center;
  animation: animateBackground 700s linear infinite;
  pointer-events: none;
  opacity: .75;

  @keyframes animateBackground {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }
`;
