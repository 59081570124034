import styled from 'styled-components';

interface IProps {
  overlappedBottom?: boolean;
  overlapTop?: boolean;
  overlapBottom?: boolean;
  overlappedTop?: boolean;
  theme?: any;
}

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100%;
  z-index: 0;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    max-height: none;
    overflow: unset;
  }
`;

export const Header = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const Main = styled.main`
  z-index: 0;
  overflow: hidden;
`;

export const Section = styled.div`
  padding: var(--x3-space) 0;
  position: relative;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    padding: var(--x6-space) 0;
  }

  /* stylelint-disable */
  ${(props: IProps) =>
    props.overlappedBottom &&
    `
    padding-bottom: calc(var(--x3-space) * 2);

    @media (min-width: ${props.theme.tabletBreak}) {
      padding-bottom: calc(var(--x6-space) * 2);
    }
  `}

  ${(props: IProps) =>
    props.overlapTop &&
    `
    margin-top: calc(var(--x3-space) * -2);
    z-index: 1;

    @media (min-width: ${props.theme.tabletBreak}) {
      margin-top: calc(var(--x6-space) * -2);
    }
  `}

  ${(props: IProps) =>
    props.overlapBottom &&
    `
    z-index: 1;
    margin-bottom: calc(var(--x3-space) * -2);

    @media (min-width: ${props.theme.tabletBreak}) {
      margin-bottom: calc(var(--x6-space) * -2);
    }
  `}

  ${(props: IProps) =>
    props.overlappedTop &&
    `
    padding-top: calc(var(--x3-space) * 2);

    @media (min-width: ${props.theme.tabletBreak}) {
      padding-top: calc(var(--x6-space) * 2);
    }
  `}
  /* stylelint-enable */
`;

export const Footer = styled.div`
  margin: 0;
  position: relative;
`;
