import * as React from 'react';
import { Grid } from 'styled-css-grid';
import CTA from '../CTA';
import Logo from '../Logo';
import Nav from '../Nav';
import Address from '../../containers/Address';
import Social from '../../containers/Social';
import { useMousePosition } from '../../utils/mouse';
import * as S from './styles';

export interface IProps {
  isActive?: boolean;
  title?: string;
  emailAddress?: string;
  onClick?: () => any;
  menuLinks: Array<{
    text: string;
    url: string;
    primary?: boolean;
  }>;
}

const defaultProps = {
  isActive: false
};

const HamburgerMenu = (props: IProps) => {
  const position = useMousePosition(props.isActive);

  return (
    <S.HamburgerMenu
      id="hamburger-menu"
      isActive={props.isActive}
      aria-hidden={!props.isActive}>
      <Grid columns={24} rows="100vh">
        <S.RCell width={22} left={2}>
          <S.Lens position={position} />
          <S.Banner>
            <S.Logo>
              <CTA type="link" to="/" title={props.title}>
                <Logo isNegative={true} />
              </CTA>
              <span aria-hidden={true}>{props.title}</span>
            </S.Logo>
          </S.Banner>
          <S.HamburgerNavigation role="navigation">
            <Nav
              links={props.menuLinks}
              color="var(--black)"
              onClick={props.onClick}
            />
          </S.HamburgerNavigation>
          <S.ContactItems>
            <S.ContactItem>
              <S.Email
                color="var(--black)"
                href={`mailto:${props.emailAddress}`}
              >
                <a href={`mailto:${props.emailAddress}`}>
                  {props.emailAddress}
                </a>
              </S.Email>
            </S.ContactItem>
            <S.ContactItem>
              <S.Address>
                <Address color="var(--black)" />
              </S.Address>
            </S.ContactItem>
            <S.ContactItem>
              <Social color="var(--black)" />
            </S.ContactItem>
          </S.ContactItems>
        </S.RCell>
      </Grid>
    </S.HamburgerMenu>
  );
};

HamburgerMenu.defaultProps = defaultProps;

export default HamburgerMenu;
