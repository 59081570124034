import * as React from 'react'
import { Link } from 'gatsby'
import { track } from '../../utils/tracking'
import * as S from './styles'

export interface IProps {
  children?: any;
  target?: string;
  title?: string;
  to: string;
  type?: 'link' | 'button';
  trackingEventName?: string;
  trackingEventOptions?: object;
  color?: string;
  ariaLabel?: string;
  display?: string;
  onClick?: (arg: any) => any;
}

const defaultProps = {
  color: 'var(--white)',
  target: '_self',
  disable: false,
  display: 'inline-block',
  children: null
}

const handleOnClick = (e, props) => {
  const { onClick, trackingEventName, trackingEventOptions } = props

  if (onClick) {
    onClick(e)
  }

  if (trackingEventName) {
    track(trackingEventName, trackingEventOptions)
  }
};

const CTA = (props: IProps) => (
  <S.CTA display={props.display}>
    {(() => {
      switch(props.type) {
        case 'link':
          return (
            <S.Link color={props.color}>
              {props.to.startsWith('/') ? // Internal link
                <Link
                  to={props.to}
                  className={props.type}
                  title={props.title}
                  target={props.target}
                  aria-label={props.ariaLabel}
                  onClick={(e) => handleOnClick(e, props)}>
                  {props.children}
                </Link> : // External link
                <a
                  href={props.to}
                  className={props.type}
                  title={props.title}
                  target={props.target}
                  aria-label={props.ariaLabel}
                  rel="noopener noreferrer"
                  onClick={(e) => handleOnClick(e, props)}>
                  {props.children}
                </a>}
              </S.Link>
            )
        case 'button':
          return (
            <S.Button color={props.color} hasLine={false}>
              {props.to.startsWith('/') ? // Internal link
                <Link
                  to={props.to}
                  title={props.title}
                  target={props.target}
                  aria-label={props.ariaLabel}
                  onClick={(e) => handleOnClick(e, props)}>
                  <button className={props.type}>
                    {props.children}
                  </button>
                </Link> : // External link
                <a
                  href={props.to}
                  title={props.title}
                  target={props.target}
                  aria-label={props.ariaLabel}
                  rel="noopener noreferrer"
                  onClick={(e) => handleOnClick(e, props)}>
                  <button className={props.type}>
                    {props.children}
                  </button>
                </a>}
            </S.Button>
          )
        default:
          return null
      }
    })()}
  </S.CTA>
)

CTA.defaultProps = defaultProps

export default CTA
