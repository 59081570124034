import * as React from 'react';
import * as S from './styles';

export interface IProps {
  color?: string;
  streetAddress?: string;
  addressLocality?: string;
  postalCode?: string;
}

const defaultProps = {
  color: 'var(--grey)'
};

const Address = (props: IProps) => {
  return (
    <S.Address color={props.color}>
      <address>
        {props.streetAddress}
        {'\n'} {props.addressLocality}
        {'\n'} {props.postalCode}
      </address>
    </S.Address>
  );
};

Address.defaultProps = defaultProps;

export default Address;
