import styled from 'styled-components';

interface IProps {
  blockAnim?: boolean;
}

export const RevealingText = styled.div`
  /* stylelint-disable */
  .block-reveal-animation {
    & .block-reveal-animation__block {
      bottom: -.5vw; /* Wraps decenders */
    }

    ${(props: IProps) => (props.blockAnim) && `
      & .block-reveal-animation__element,
      & .block-reveal-animation__block {
        animation-name: none;
      }
    `};
  }
  /* stylelint-enable */
`;
