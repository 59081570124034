import * as React from 'react';
import ReactBreakpoints from 'react-breakpoints';
import { ThemeProvider } from 'styled-components';
import {
  GlobalStyle,
  GlobalTheme,
  GlobalBreakpoints
} from '../StyleGuide/global';
import Banner from '../../containers/Banner';
import Footer from '../../containers/Footer';
import * as S from './styles';
import './fonts.css';

export interface IProps {
  children: any;
}

const defaultProps = {
  children: null
};

const Layout = (props: IProps) => {
  return (
    <ReactBreakpoints breakpoints={GlobalBreakpoints}>
      <ThemeProvider theme={GlobalTheme}>
        <S.Layout>
          <GlobalStyle />
          <S.Header>
            <Banner />
          </S.Header>
          <S.Main role="main">{props.children}</S.Main>
          <S.Footer>
            <Footer />
          </S.Footer>
        </S.Layout>
      </ThemeProvider>
    </ReactBreakpoints>
  );
};

Layout.defaultProps = defaultProps;

export default Layout;
