import styled from 'styled-components'
import { Section }  from '../Layout/styles'
import * as T from '../Typography'

interface IProps {
  backgroundColor: string;
  color: string;
}

export const Quote = styled(Section)`
  /* stylelint-disable */
  ${(props: IProps) => props.backgroundColor === '#E8FF00' && `
    background-color: ${props.backgroundColor};
    *::selection {
      background-color: var(--grey);
    }
  `}
  /* stylelint-enable */
`

export const Label = styled(T.Label)``

export const Text = styled.div`
  color: ${(props: IProps) => props.color};
  margin-top: var(--x1-space);
  line-height: 2.25rem;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    line-height: 3rem;
  }
`

export const Button = styled.div`
  margin-top: var(--x2-space);
`
