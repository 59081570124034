import styled from 'styled-components';

interface IProps {
  blockAnim?: boolean;
}

export const RevealingImage = styled.div`
  width: 100%;
  
  /* stylelint-disable */
  .block-reveal-animation {
    width: 100%;

    ${(props: IProps) => (props.blockAnim) && `
      & .block-reveal-animation__element,
      & .block-reveal-animation__block {
        animation-name: none;
      }
    `};
  }
  /* stylelint-enable */
`;
