import * as React from 'react';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  color: 'var(--grey)',
  width: '1396px',
  height: '526px'
};

const Zero1 = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 1396 526" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Redesign" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
          <g id="Inner-1" transform="translate(0.000000, -262.000000)">
              <rect fill="#000000" x="0" y="0" width="1440" height="5531" />
              <g id="BG" opacity="0.205450149" transform="translate(-605.000000, 263.000000)" stroke="#FFFFFF">
                  <polygon id="Fill-2" points="377.056584 67.915016 96.5475062 448.128856 373.556658 448.128856 373.556658 516.740296 0 516.740296 0 455.124656 280.519588 74.910816 0 74.910816 0 6.299376 377.056584 6.299376" />
                  <polygon id="Fill-3" points="520.465261 6.295168 864.645664 6.295168 864.645664 74.212288 596.717401 74.212288 596.717401 221.260848 831.065294 221.260848 831.065294 285.674808 596.717401 285.674808 596.717401 448.818968 864.645664 448.818968 864.645664 516.736088 520.465261 516.736088" />
                  <path d="M1210.64435,254.871196 C1276.39671,254.871196 1316.96642,217.051796 1316.96642,161.737636 C1316.96642,98.028516 1278.49877,73.516916 1219.73575,73.516916 L1081.92485,73.516916 L1081.92485,254.871196 L1210.64435,254.871196 Z M1005.67271,6.294116 L1237.22487,6.294116 C1344.95532,6.294116 1397.42268,65.111436 1397.42268,152.637836 C1397.42268,213.548636 1364.5465,257.669516 1315.56855,280.077116 L1315.56855,284.968916 C1365.93385,296.183236 1388.32077,331.193796 1388.32077,383.699116 L1388.32077,459.327396 C1388.32077,477.526996 1388.32077,497.841116 1396.02481,516.745556 L1318.3748,516.745556 C1311.37495,502.743436 1310.68127,485.932476 1310.68127,455.119396 L1310.68127,397.701236 C1310.68127,347.994236 1293.88583,322.093996 1233.72494,322.093996 L1081.92485,322.093996 L1081.92485,516.745556 L1005.67271,516.745556 L1005.67271,6.294116 Z" id="Fill-4" />
                  <path d="M1759.76591,454.42192 C1859.81334,454.42192 1920.66791,377.405 1920.66791,261.86384 C1920.66791,143.53488 1857.00709,67.91712 1759.76591,67.91712 C1661.12686,67.91712 1599.57861,144.93404 1599.57861,261.16952 C1599.57861,380.20332 1662.53523,454.42192 1759.76591,454.42192 M1759.76591,2.84217094e-14 C1908.06607,2.84217094e-14 2000.40946,105.726 2000.40946,261.16952 C2000.40946,422.20968 1904.57666,523.74872 1759.76591,523.74872 C1610.76155,523.74872 1519.12235,416.61304 1519.12235,261.86384 C1519.12235,102.22284 1615.65935,2.84217094e-14 1759.76591,2.84217094e-14" id="Fill-6" />
                  <path d="M2347.39717,100.121996 L2364.19261,100.121996 C2432.04703,100.121996 2476.81034,67.215436 2484.51438,11.890756 L2551.67512,11.890756 L2551.67512,516.745556 L2479.61659,516.745556 L2479.61659,155.436156 L2347.39717,155.436156 L2347.39717,100.121996 Z" id="Fill-8" />
              </g>
          </g>
      </g>
  </svg>
);

Zero1.defaultProps = defaultProps;

export default Zero1;
