import styled from 'styled-components';

export interface IProps {
  size: string;
  color: string;
}

export const Social = styled.div`
  margin-top: 1.5rem;
  list-style: none;
  text-align: left;
`;

export const SocialItems = styled.ul`
  list-style: none;
`;

export const SocialItem = styled.li`
  list-style: none;
  display: inline-block;
  opacity: 1;

  &:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const SocialLink = styled.a`
  display: block;
  width: ${(props: IProps) => props.size};
  height: ${(props: IProps) => props.size};
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${(props: IProps) => props.color};
    border-radius: calc(${(props: IProps) => props.size} / 2);
    opacity: .1;
    transition: 0.5s opacity;
  }

  &:hover {
    &::before {
      opacity: .2;
    }
  }

  /* stylelint-disable */
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  /* stylelint-enable */
`;
