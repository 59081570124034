import styled from 'styled-components';
import { Cell } from 'styled-css-grid';
import * as T from '../Typography';
import * as bgZero1 from '../../images/bgZero1.svg';

export const Header = styled.div`
  margin-top: var(--x7-space);
  /* background-image: url(${bgZero1});
  background-repeat: repeat-x;
  background-position: center center;
  background-size: auto 75%;
  animation: animateBackground 500s linear infinite; */
`;

export const RCell = styled(Cell)`
  position: relative;
`;

export const Headline = styled.div`
  font-family: var(--font-mark-pro-medium);
  color: var(--white);
  margin-top: var(--x2-space);
  text-align: left;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    && {
      /* stylelint-disable */
      font-size: 6vw;
      line-height: 6.5vw;
      /* stylelint-enable */
    }
  }
`;

export const Straplines = styled.div`
  width: 100%;
  color: var(--white);
  position: absolute;
  right: 0;
  bottom: 10%;
  text-align: left;
`;

export const Slider = styled(T.H4)`
  width: 50%;
  float: right;
  position: relative;

  &::before {
    display: inline-block;
    content: '─';
    position: absolute;
    /* stylelint-disable-next-line */
    left: -1.25em;
    top: 0;
  }

  @media (min-width: ${props => props.theme.tabletBreak}) {
    font: 400 var(--type-0) var(--font-mark-pro-medium);
    letter-spacing: -3px;
    /* stylelint-disable */
    && {
      font-size: 6vw;
      line-height: 6.5vw;
    }
    /* stylelint-enable */

    & .slick-list {
      overflow: visible; /* Reveals decenders */
    }
  }
`;

export const Text = styled(T.H6)`
  opacity: 0;
  font-family: var(--font-mark-pro-book);

  &.appear-done {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  @media (min-width: ${props => props.theme.tabletBreak}) {
    width: 50%;
    float: right;
    /* stylelint-disable */
    && {
      font-size: 3vw;
      line-height: 4vw;
    }
    /* stylelint-enable */
  }
`;

export const Address = styled(T.TinyBodyCopy)`
  display: none;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    color: var(--white);
    writing-mode: tb-rl;
    transform: translateY(-50%) rotate(-180deg);
  }
`;

export const Scroll = styled(T.Label)`
  display: none;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    display: block;
    color: var(--grey);
    position: absolute;
    bottom: 50px;
    left: calc(var(--x2-space) * -1);
    width: 100%;

    /* stylelint-disable */
    span {
      position: relative;

      &::before {
        background-color: var(--grey);
        content: '';
        height: 100px;
        width: 1px;
        position: absolute;
        top: -120px;
        left: 50%;
      }
    }
    /* stylelint-enable */
  }
`;
