import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Banner from '../components/Banner'

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteBannerQuery {
        global: markdownRemark(
          frontmatter: { templateKey: { eq: "global" } }
        ) {
          frontmatter {
            title
            menuLinks {
              text
              url
              primary
            }
          }
        }
      }
    `}
    render={data => <Banner {...data.global.frontmatter} {...props} />}
  />
)
