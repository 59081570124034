import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import HamburgerMenu from '../components/HamburgerMenu'

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteHamburgerMenuQuery {
        global: markdownRemark(
          frontmatter: { templateKey: { eq: "global" } }
        ) {
          frontmatter {
            title
            emailAddress
          }
        }
      }
    `}
    render={data => <HamburgerMenu {...data.global.frontmatter} {...props} />}
  />
)
