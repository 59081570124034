import * as React from 'react';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  color: 'var(--white)',
  width: '21px',
  height: '17px'
};

const Tweet = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 21 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Redesign" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Homepage" transform="translate(-300.000000, -4750.000000)" fill={props.color}>
        <path d="M306.60408,4767 C314.52864,4767 318.86304,4760.45908 318.86304,4754.78683 C318.86304,4754.60104 318.86304,4754.4161 318.85044,4754.23199 C319.693658,4753.62435 320.421539,4752.87199 321,4752.01012 C320.213658,4752.35725 319.379497,4752.5849 318.52536,4752.68547 C319.424785,4752.14902 320.097944,4751.30529 320.41956,4750.31129 C319.573812,4750.81127 318.648543,4751.16364 317.68368,4751.35318 C316.34815,4749.93838 314.226004,4749.5921 312.507221,4750.50852 C310.788439,4751.42494 309.900471,4753.37615 310.34124,4755.26802 C306.876992,4755.095 303.64936,4753.46486 301.4616,4750.78328 C300.318043,4752.74459 300.90215,4755.25368 302.79552,4756.51328 C302.109863,4756.49303 301.439155,4756.30876 300.84,4755.97601 C300.84,4755.99358 300.84,4756.01199 300.84,4756.03041 C300.840561,4758.07368 302.286271,4759.83356 304.2966,4760.23815 C303.66229,4760.41049 302.996768,4760.43569 302.35116,4760.31179 C302.915596,4762.06035 304.533123,4763.25821 306.37644,4763.2927 C304.850777,4764.48726 302.96609,4765.13574 301.02564,4765.1338 C300.682839,4765.13314 300.340371,4765.11246 300,4765.07187 C301.970334,4766.33158 304.262927,4766.99976 306.60408,4766.99665" id="Path" />
      </g>
    </g>
  </svg>
);

Tweet.defaultProps = defaultProps;

export default Tweet;
