import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Footer from '../components/Footer'

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteFooterQuery {
        global: markdownRemark(
          frontmatter: { templateKey: { eq: "global" } }
        ) {
          frontmatter {
            strapline
            footerLinks {
              text
              items {
                text
                url
              }
            }
            copyright
            legalLinks {
              text
              url
            }
          }
        }
      }
    `}
    render={data => <Footer {...data.global.frontmatter} {...props} />}
  />
)
