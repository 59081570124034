import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import CTA from '../CTA';
import * as T from '../Typography';
import * as S from './styles';
import ReactMarkdown from 'react-markdown';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  color?: string;
  backgroundColor?: string;
  label: string;
  text: string;
  button?: any;
}

const defaultProps = {
  color: 'var(--white)',
  backgroundColor: 'var(--black)'
};

const Quote = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Quote backgroundColor={props.backgroundColor}>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <Grid columns={12}>
            <Cell width={isMobile ? 11 : 7} left={isMobile ? 1 : 3}>
              <S.Label color={props.color} hasLine={true}>
                {props.label}
              </S.Label>
              <S.Text color={props.color} as={isMobile ? T.H5 : T.H4}>
                <ReactMarkdown source={props.text} />
              </S.Text>
              {props.button && (
                <S.Button>
                  <CTA
                    type="button"
                    to={props.button.url}
                    title={props.button.label}
                    color={props.color}
                  >
                    {props.button.label}
                  </CTA>
                </S.Button>
              )}
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </S.Quote>
  );
};

Quote.defaultProps = defaultProps;

export default withBreakpoints(Quote);
