import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import IconNewModel from '../Icons/NewModel';
import CTA from '../CTA';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  section1: {
    headline: string;
    columns?: Array<{
      title: string;
      description: string;
    }>;
  };
  section2: {
    headline: string;
    columns?: Array<{
      title: string;
      description: string;
    }>;
  };
  button2: {
    label: string;
    url: string;
  };
  index: number;
}

const Section = props => {
  return (
    <S.Section>
      <Grid columns={12}>
        <Cell width={12} left={1}>
          <h4>
            <S.Headline>{props.section.headline}</S.Headline>
          </h4>
        </Cell>
        {props.section.columns &&
          props.section.columns.map((column, index) => (
            <Cell
              key={index}
              width={props.isMobile ? 10 : 5}
              left={(props.isMobile || !(index % 2)) ? 1 : 7}>
              <S.Column>
                <h5>
                  <S.Title>{column.title}</S.Title>
                </h5>
                <S.Description>{column.description}</S.Description>
              </S.Column>
            </Cell>
          ))}
      </Grid>
    </S.Section>
  );
};

const Graphic = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Graphic>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <S.Sections>
            <Grid columns={12}>
              <Cell width={isMobile ? 12 : 7} left={1}>
                <Section section={props.section1} isMobile={isMobile} />
                <S.NewModelContainer>
                  <Grid columns={12} style={{ height: '100%' }}>
                    <Cell width={isMobile ? 10 : 12} left={isMobile ? 2 : 9}>
                      <S.IconNewModel>
                        <IconNewModel width="100%" height="100%" />
                      </S.IconNewModel>
                    </Cell>
                  </Grid>
                </S.NewModelContainer>
                <Section section={props.section2} isMobile={isMobile} />
              </Cell>
            </Grid>
          </S.Sections>
          <Grid columns={12}>
            <Cell width={12} left={1}>
              <S.Button>
                <CTA
                  type="button"
                  to={props.button2.url}
                  title={props.button2.label}
                  color="var(--black)"
                >
                  {props.button2.label}
                </CTA>
              </S.Button>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </S.Graphic>
  );
};

export default withBreakpoints(Graphic);
