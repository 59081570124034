import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import RevealingImage from '../RevealingImage';
import * as T from '../Typography';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  overlapTop?: boolean;
  overlapBottom?: boolean;
  image: any;
  label: string;
  color: string;
  headline?: any;
  strapline?: any;
  text?: any;
}

const defaultProps = {
  color: 'var(--white)'
};

const Media = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Media overlapTop={props.overlapTop} overlapBottom={props.overlapBottom}>
      <Grid columns={24}>
        <Cell width={isMobile ? 18 : 12} left={1}>
          <RevealingImage>
            <Img
              backgroundColor="var(--black)"
              className="image"
              fluid={props.image && props.image.childImageSharp.fluid}
            />
          </RevealingImage>
        </Cell>
        <Cell width={isMobile ? 20 : 8} left={isMobile ? 2 : 15}>
          <S.OffsetWrapper>
            {props.label && (
              <S.Label color={props.color} hasLine={true}>
                {props.label}
              </S.Label>
            )}
            {props.headline && (
              <S.Headline as={isMobile ? T.H5 : T.H2}>
                <ReactMarkdown source={props.headline} escapeHtml={false} renderers={{root: 'h2'}} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
              </S.Headline>
            )}
            {props.strapline && (
              <S.Strapline as={isMobile ? T.H5 : T.H3}>
                <ReactMarkdown source={props.strapline} escapeHtml={false} />
              </S.Strapline>
            )}
            {props.text && (
              <S.Text>
                <ReactMarkdown source={props.text} escapeHtml={false} />
              </S.Text>
            )}
          </S.OffsetWrapper>
        </Cell>
      </Grid>
    </S.Media>
  );
};

Media.defaultProps = defaultProps;

export default withBreakpoints(Media);
