import styled from 'styled-components';
import * as T from '../Typography';

interface IProps {
  color?: string;
}

export const Address = styled(T.LargeBodyCopy)`
  color: ${(props: IProps) => props.color};
  margin-top: 1rem;

  /* stylelint-disable */
  address {
    font-style: normal;
    white-space: pre-line;
  }
  /* stylelint-enable */
`
