import * as React from 'react';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  color: 'var(--white)',
  width: '15px',
  height: '12px'
};

const Twitter = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.81573 3.08578L6.84615 3.58751L6.33903 3.52608C4.49311 3.29057 2.88046 2.49189 1.51123 1.15053L0.841823 0.484963L0.669402 0.976456C0.304273 2.07208 0.53755 3.22913 1.29823 4.00733C1.70393 4.43739 1.61265 4.49883 0.912821 4.24284C0.669402 4.16092 0.45641 4.09949 0.436125 4.1302C0.365128 4.20188 0.608547 5.13367 0.801254 5.50229C1.06496 6.01426 1.60251 6.516 2.19077 6.81294L2.68775 7.04845L2.09949 7.05869C1.53151 7.05869 1.51123 7.06892 1.57208 7.28395C1.77493 7.94952 2.57618 8.65604 3.46872 8.96322L4.09755 9.17825L3.54986 9.50591C2.73846 9.97693 1.78507 10.2432 0.831681 10.2636C0.375271 10.2739 0 10.3148 0 10.3455C0 10.4479 1.23738 11.0214 1.95749 11.2466C4.11784 11.9122 6.68387 11.6255 8.61094 10.4889C9.98017 9.67998 11.3494 8.07239 11.9884 6.516C12.3332 5.6866 12.6781 4.17116 12.6781 3.44416C12.6781 2.97315 12.7085 2.91171 13.2765 2.34854C13.6112 2.02088 13.9256 1.6625 13.9864 1.5601C14.0879 1.36556 14.0777 1.36556 13.5605 1.53963C12.6983 1.84681 12.5766 1.80585 13.0026 1.34508C13.317 1.01741 13.6923 0.423526 13.6923 0.249456C13.6923 0.218738 13.5402 0.269935 13.3677 0.36209C13.1852 0.464484 12.7795 0.618076 12.4752 0.710231L11.9275 0.884301L11.4305 0.5464C11.1567 0.36209 10.7713 0.157301 10.5684 0.0958642C10.0512 -0.047488 9.26006 -0.0270091 8.7935 0.136822C7.5257 0.597597 6.72444 1.78537 6.81573 3.08578Z" fill={props.color} />
  </svg>
);

Twitter.defaultProps = defaultProps;

export default Twitter;
