import styled from 'styled-components'
import { Section }  from '../Layout/styles'
import * as T from '../Typography'

interface IProps {
  backgroundColor: string;
}

export const Carousel = styled(Section)`
  background-color: ${(props: IProps) => props.backgroundColor};
`

export const Heading = styled.div`
  @media (min-width: ${props => props.theme.tabletBreak}) {
    width: 45vw;
    margin: 0 auto;
  }
`

export const Label = styled(T.Label)`
  margin-top: var(--x4-space);
`

export const Headline = styled.div`
  margin-top: var(--x1-space);
  line-height: 2.25rem;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    line-height: 3rem;
  }
`

export const Slider = styled.div`
  margin-top: var(--x4-space);

  & .slick-slider {
    width: 100%;
  }

  /* stylelint-disable */
  & .slick-arrow {
    z-index: 2;
    transform: translateY(-50%);
    margin-top: calc(var(--x6-space) * -1);
    width: auto;
    height: auto;

    &::before {
      display: none;
    }

    &.slick-prev {
      left: 5%;

    }

    &.slick-next {
      right: 5%;
    }
  }

  & .slick-slide {
    padding: 0 var(--x1-space);

    .image {
      background-color: black;

      picture {
        opacity: .4;
        transition: opacity .5s;
      }
    }

    &.slick-current {
      .image {
        picture {
          opacity: 1;
        }
      }
    }
  }

  & .slick-dots {
    margin-top: var(--x2-space);
    position: static;
    text-align: left;

    @media (min-width: ${props => props.theme.tabletBreak}) {
      width: 45vw;
      margin: var(--x4-space) auto 0 auto;
    }

    li {
      width: auto;
      height: auto;

      &:first-child {
        margin-left: 0;
      }
    }

    button {
      width: auto;
      height: auto;
      padding: 0;

      &::before {
        border-top: 3px solid var(--white);
        padding: 0 var(--x1-space);
        width: 45px;
        position: static;
        font-size: 0;
        display: block;
      }
    }
  }
  /* stylelint-enable */
`

export const Figure = styled.div`
  width: 90vw;
  position: relative;
  display: inline-block;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    width: 45vw;
    max-width: 640px;
  }

  /* stylelint-disable */
  .image {
    width: 100%;
    margin-bottom: var(--x5-space);
  }
  /* stylelint-enable */
`

export const FigCaption = styled(T.PetiteBodyCopy)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: var(--x4-space);
`
