import * as React from 'react'
import * as S from './styles'

export interface IProps {
  isNegative?: boolean
}

const defaultProps={
  isNegative: false
}

const Logo = (props: IProps) => (
  <S.Logo>
    <svg width="114px" height="37px" viewBox="0 0 114 37" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Redesign" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Homepage" transform="translate(-90.000000, -49.000000)">
          <g id="New-logo" transform="translate(90.000000, 49.000000)">
            <g id="Group-11">
              <polygon id="Fill-1" fill={props.isNegative ? 'black' : 'white'} points="0 36.6386837 113.949898 36.6386837 113.949898 0.00037755102 0 0.00037755102" />
              <polygon id="Fill-2" fill={props.isNegative ? 'var(--yellow)' : 'black'} points="25.9283924 11.3711949 16.2387554 25.0166439 25.8074943 25.0166439 25.8074943 27.4790316 12.9037108 27.4790316 12.9037108 25.2677153 22.5937108 11.6222663 12.9037108 11.6222663 12.9037108 9.15987857 25.9283924 9.15987857" />
              <polygon id="Fill-3" fill={props.isNegative ? 'var(--yellow)' : 'black'} points="30.8821643 9.15972755 42.7712025 9.15972755 42.7712025 11.5971969 33.5161452 11.5971969 33.5161452 16.8746051 41.6112344 16.8746051 41.6112344 19.18635 33.5161452 19.18635 33.5161452 25.0414112 42.7712025 25.0414112 42.7712025 27.4788806 30.8821643 27.4788806" />
              <g id="Group-10" transform="translate(47.560510, 8.699908)" fill={props.isNegative ? 'var(--yellow)' : 'black'}>
                <path d="M7.16254013,9.38093469 C9.43382675,9.38093469 10.835228,8.02363878 10.835228,6.03847551 C10.835228,3.75202653 9.50643822,2.87233265 7.47658471,2.87233265 L2.71617707,2.87233265 L2.71617707,9.38093469 L7.16254013,9.38093469 Z M0.0821961783,0.459781633 L8.0807121,0.459781633 C11.8020497,0.459781633 13.6144318,2.57066939 13.6144318,5.71189388 C13.6144318,7.89791429 12.4787885,9.48136327 10.7869414,10.2855469 L10.7869414,10.4611082 C12.5267121,10.8635776 13.3000242,12.1200673 13.3000242,14.0044245 L13.3000242,16.7186388 C13.3000242,17.371802 13.3000242,18.1008531 13.5661452,18.7793122 L10.8838777,18.7793122 C10.6420815,18.2767918 10.6181197,17.6734653 10.6181197,16.5676184 L10.6181197,14.5069449 C10.6181197,12.7230163 10.0379541,11.7934857 7.95981401,11.7934857 L2.71617707,11.7934857 L2.71617707,18.7793122 L0.0821961783,18.7793122 L0.0821961783,0.459781633 Z" id="Fill-4" />
                <path d="M26.1308694,16.5425867 C29.5868121,16.5425867 31.688914,13.7785357 31.688914,9.63189286 C31.688914,5.38519898 29.4898758,2.67136224 26.1308694,2.67136224 C22.7235764,2.67136224 20.5975127,5.43541327 20.5975127,9.60697449 C20.5975127,13.8789643 22.7722261,16.5425867 26.1308694,16.5425867 M26.1308694,0.233892857 C31.2536083,0.233892857 34.4434299,4.02828061 34.4434299,9.60697449 C34.4434299,15.3865255 31.1330732,19.030648 26.1308694,19.030648 C20.9838057,19.030648 17.8183089,15.1856684 17.8183089,9.63189286 C17.8183089,3.90255612 21.1529904,0.233892857 26.1308694,0.233892857" id="Fill-6" />
                <path d="M46.4294408,3.82715918 L47.0096064,3.82715918 C49.3535045,3.82715918 50.8997656,2.64617959 51.1658866,0.660638776 L53.4858229,0.660638776 L53.4858229,18.7793122 L50.9967019,18.7793122 L50.9967019,5.81232245 L46.4294408,5.81232245 L46.4294408,3.82715918 Z" id="Fill-8" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </S.Logo>
)

Logo.defaultProps = defaultProps

export default Logo
