import * as React from 'react';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  color: 'var(--black)',
  width: '70px',
  height: '53px'
};

const Quote = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 70 53" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Redesign" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Inner-1" transform="translate(-415.000000, -3344.000000)" fill={props.color}>
          <path d="M437.569755,3344 L446.555862,3353.00791 C439.868493,3358.45457 436.037246,3363.7615 435.062005,3368.92885 C437.848408,3369.90646 440.147157,3371.65216 441.958319,3374.16601 C443.769481,3376.67985 444.675049,3379.47298 444.675049,3382.54545 C444.675049,3386.59554 443.247038,3390.01711 440.390975,3392.81028 C437.534911,3395.60344 434.086789,3397 430.046504,3397 C425.866898,3397 422.314287,3395.56853 419.388564,3392.70553 C416.46284,3389.84254 415,3385.96709 415,3381.07905 C415,3369.9064 422.523177,3357.54684 437.569755,3344 Z M476.013894,3344 L485,3353.00791 C478.312632,3358.45457 474.481384,3363.7615 473.506143,3368.92885 C476.292547,3369.90646 478.591295,3371.65216 480.402457,3374.16601 C482.213619,3376.67985 483.119187,3379.47298 483.119187,3382.54545 C483.119187,3386.59554 481.691177,3390.01711 478.835113,3392.81028 C475.97905,3395.60344 472.530927,3397 468.490642,3397 C464.311037,3397 460.758425,3395.56853 457.832702,3392.70553 C454.906978,3389.84254 453.444138,3385.96709 453.444138,3381.07905 C453.444138,3369.9064 460.967315,3357.54684 476.013894,3344 Z" id="" />
      </g>
    </g>
  </svg>
);

Quote.defaultProps = defaultProps;

export default Quote;
