import { useEffect, useState } from 'react';

export function useMobileBreakpoint(breakpoints, currentBreakpoint) {
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    if (
      typeof isMobile === 'undefined' ||
      isMobile !== breakpoints[currentBreakpoint] < breakpoints.tablet
    ) {
      setIsMobile(breakpoints[currentBreakpoint] < breakpoints.tablet);
    }
  });
  return isMobile;
}
