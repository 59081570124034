import * as React from 'react';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  color: 'var(--white)',
  width: '22px',
  height: '15px'
};

const Crunchbase = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7581 4.84375C15.6452 4.84375 14.629 5.17188 13.7742 5.75V0H11.5161V14.6875H13.7742V14.0938C14.629 14.6562 15.6452 15 16.7581 15C19.6452 15 22 12.7188 22 9.92188C22 7.125 19.6452 4.84375 16.7581 4.84375ZM8.01613 11.1406C7.54839 12.1562 6.5 12.8594 5.27419 12.8594C3.6129 12.8594 2.25806 11.5469 2.25806 9.9375C2.25806 8.32812 3.6129 7.01562 5.27419 7.01562C6.5 7.01562 7.54839 7.71875 8.01613 8.73438H10.3387C9.79032 6.5 7.70968 4.82812 5.24194 4.82812C2.35484 4.82812 0 7.10938 0 9.90625C0 12.7031 2.35484 14.9844 5.24194 14.9844C7.69355 14.9844 9.75806 13.3438 10.3226 11.125H8.01613V11.1406ZM13.7742 9.9375C13.7742 11.5469 15.129 12.8594 16.7903 12.8594C18.4516 12.8594 19.7903 11.5625 19.8065 9.9375C19.8065 8.32812 18.4516 7.01562 16.7903 7.01562C15.129 7.01562 13.7742 8.32812 13.7742 9.9375Z" fill={props.color} />
  </svg>
);

Crunchbase.defaultProps = defaultProps;

export default Crunchbase;
