import styled from 'styled-components';
import { Section as LayoutSection } from '../Layout/styles';
import * as T from '../Typography';

export const Graphic = styled(LayoutSection)`
  /* stylelint-disable */
  background-color: var(--yellow);
  *::selection {
    background-color: var(--grey);
  }
  /* stylelint-enable */
`;

export const Sections = styled.div`
  position: relative;
`;

export const Section = styled.div`
  padding-bottom: var(--x2-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    border-top: 1px solid var(--black);
    padding-bottom: var(--x3-space);
    position: relative;
    z-index: 2;
  }
`;

export const Column = styled.div`
  margin-top: var(--x1-space);
`;

export const Headline = styled(T.H6)`
  color: var(--black);
  margin-top: var(--x1-space);
  padding-bottom: var(--xhalf-space);
  border-bottom: 1px solid var(--black);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const Title = styled(T.PetiteBodyCopy)`
  color: var(--black);
  font-family: var(--font-mark-pro-medium);
`;

export const Description = styled(T.PetiteBodyCopy)`
  color: var(--bodyCopyOnYellow);
  margin-top: var(--xhalf-space);
`;

export const NewModelContainer = styled.div`
  margin-bottom: var(--x1-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    z-index: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    margin-bottom: 0;
  }
`;

export const IconNewModel = styled.div`
  padding: var(--x1-space);
  animation-name: spin;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;

  /* stylelint-disable */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* stylelint-enable */

  @media (min-width: ${props => props.theme.tabletBreak}) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

export const Button = styled(T.Label)`
  text-align: center;
  margin: var(--x1-space) 0 var(--x2-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin: var(--x2-space) 0 0;
  }
`;
