import styled from 'styled-components'
import { Section }  from '../Layout/styles'
import * as T from '../Typography'

export const Email = styled(Section)`
  padding-bottom: var(--x5-space);
  background-color: black;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    padding-bottom: var(--x8-space);
  }
`

export const Label = styled(T.Label)``

export const Address = styled.div`
  margin-top: var(--x2-space);
  font-family: var(--font-mark-pro-bold);

  /* stylelint-disable */
  & a {
    border-bottom: 2px solid var(--white);
  }

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: var(--x1-space);

    && {
      font-size: 6vw;
      line-height: 6.5vw;
    }

    & a {
      border-bottom: 5px solid var(--white);
    }
  }
  /* stylelint-enable */
`
