import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Grid, Cell } from 'styled-css-grid';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import RevealingText from '../RevealingText';
import { withBreakpoints } from 'react-breakpoints';
import { useMobileBreakpoint } from '../../utils/breakpoints';
import * as T from '../Typography';
import * as S from './styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface IProps {
  headline: any;
  breakpoints: any;
  currentBreakpoint: string;
  straplines?: string[];
  address: string;
}

const Header = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);
  const [initStraplines, setInitStraplines] = React.useState(false);
  const [blockHeadlineAnim, setBlockHeadlineAnim] = React.useState(true);
  const [blockSliderAnims, setBlockSliderAnims] = React.useState(
    new Array(props.straplines ? props.straplines.length : 0)
  );
  const setBlockSliderAnim = (index, value) => {
    const array = [...blockSliderAnims];

    array[index] = value;
    setBlockSliderAnims(array);
  };
  const slickSettings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (currentSlide, nextSlide) => setBlockSliderAnim(nextSlide, true),
    afterChange: currentSlide => setBlockSliderAnim(currentSlide, false)
  };

  React.useEffect(() => {
    setTimeout(() => {
      setBlockHeadlineAnim(false);
    }, 1000);
    setTimeout(() => {
      setInitStraplines(true);
    }, 2500);
  });

  return (
    <S.Header>
      <Grid columns={24} rows={`calc(100vh - var(--x7-space))`}>
        <S.RCell width={22} left={2}>
          <Grid columns={12}>
            <Cell width={12} left={isMobile ? 1 : 2}>
              <S.Headline as={isMobile ? T.H4 : T.H1}>
                <h1>
                  <RevealingText
                    blockAnim={blockHeadlineAnim}
                    text={props.headline} />
                </h1>
              </S.Headline>
            </Cell>
          </Grid>
          {initStraplines && props.straplines && (
            <S.Straplines>
              {props.straplines.length > 1 ? (
                <S.Slider>
                  <Slider {...slickSettings}>
                    {props.straplines.map((strapline, i) => (
                      <div key={i}>
                        <RevealingText
                          blockAnim={blockSliderAnims[i]}
                          text={strapline}
                        />
                      </div>
                    ))}
                  </Slider>
                </S.Slider>
              ) : (
                <CSSTransition in={true} appear={true} timeout={0}>
                  <S.Text as={isMobile ? T.H6 : T.H4}>
                    <ReactMarkdown source={props.straplines[0]} />
                  </S.Text>
                </CSSTransition>
              )}
            </S.Straplines>
          )}
          <S.Address>
            <span>{props.address}</span>
          </S.Address>
          <S.Scroll>
            <Grid columns={12}>
              <S.RCell width={12} left={2}>
                <span>Scroll</span>
              </S.RCell>
            </Grid>
          </S.Scroll>
        </S.RCell>
      </Grid>
    </S.Header>
  );
};

export default withBreakpoints(Header);
