import * as React from 'react'
import * as S from './styles'

export interface IProps {
  children?: any;
  className?: string;
  hasLine?: boolean;
  color?: string;
}

export const H1 = (props: IProps) => (
  <S.H1 className={props.className}>{props.children}</S.H1>
)

export const H2 = (props: IProps) => (
  <S.H2 className={props.className}>{props.children}</S.H2>
)

export const H3 = (props: IProps) => (
  <S.H3 className={props.className}>{props.children}</S.H3>
)

export const H4 = (props: IProps) => (
  <S.H4 className={props.className}>{props.children}</S.H4>
)

export const H5 = (props: IProps) => (
  <S.H5 className={props.className}>{props.children}</S.H5>
)

export const H6 = (props: IProps) => (
  <S.H6 className={props.className}>{props.children}</S.H6>
)

export const Nav = (props: IProps) => (
  <S.Nav className={props.className}>{props.children}</S.Nav>
)

export const Label = (props: IProps) => (
  <S.Label
    className={props.className}
    hasLine={props.hasLine}
    color={props.color}>{props.children}</S.Label>
)

export const HugeBodyCopy = (props: IProps) => (
  <S.HugeBodyCopy className={props.className}>{props.children}</S.HugeBodyCopy>
)

export const LargeBodyCopy = (props: IProps) => (
  <S.LargeBodyCopy className={props.className}>{props.children}</S.LargeBodyCopy>
)

export const SmallBodyCopy = (props: IProps) => (
  <S.SmallBodyCopy className={props.className}>{props.children}</S.SmallBodyCopy>
)

export const PetiteBodyCopy = (props: IProps) => (
  <S.PetiteBodyCopy className={props.className}>{props.children}</S.PetiteBodyCopy>
)

export const TinyBodyCopy = (props: IProps) => (
  <S.TinyBodyCopy className={props.className}>{props.children}</S.TinyBodyCopy>
)
