import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Media from '../components/Media';
import Column from '../components/Column';
import Quote from '../components/Quote';
import Graphic from '../components/Graphic';
import Carousel from '../components/Carousel';
import Accordion from '../components/Accordion';
import BoxImage from '../components/BoxImage';
import Email from '../components/Email';
import Background from '../components/Background';
import Head from '../components/Head';

const IndexPage = ({ data }) => {
  const homepage = data.homepage.frontmatter;

  return (
    <Layout>
      <Background />
      <Head title={homepage.title} description={homepage.description} />
      <Header {...homepage.header} />
      <Media {...homepage.media} />
      <Column {...homepage.column} hasLine={false} />
      <Quote {...homepage.quote} />
      <Graphic {...homepage.graphic} />
      <Carousel {...homepage.carousel} />
      {/* <News maxPosts={1} maxTweets={1} /> */}
      <BoxImage {...homepage.boxImage2} />
      <Quote {...homepage.quoteCTA} />
      <Accordion {...homepage.faq} />
      <Email {...homepage.email} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query SiteIndexPageQuery {
    homepage: markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
      frontmatter {
        title
        description
        header {
          headline
          straplines
          address
        }
        media {
          image {
            childImageSharp {
              fluid(quality: 85, maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          label
          headline
          strapline
          text
        }
        column {
          backgroundColor
          color
          label
          headline
          text1
          text2
        }
        quote {
          label
          text
        }
        graphic {
          section1 {
            headline
            columns {
              title
              description
            }
          }
          section2 {
            headline
            columns {
              title
              description
            }
          }
          button2 {
            label
            url
          }
        }
        carousel {
          backgroundColor
          label
          headline
          slides {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            caption
          }
        }
        faq {
          label
          questions {
            title
            body
          }
        }
        boxImage2 {
          boxes2 {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            headline
            button {
              label
              url
            }
          }
        }
        quoteCTA {
          label
          text
          color
          backgroundColor
          button {
            label
            url
          }
        }
        email {
          label
          address
        }
      }
    }
  }
`;

export default IndexPage;
