import * as React from 'react';
import IconHamburger from '../Icons/Hamburger';
import * as S from './styles';

export interface IProps {
  isActive?: boolean;
  onClick?: (arg: any) => any;
}

const defaultProps = {
  isActive: false
};

const Hamburger = (props: IProps) => (
  <S.Hamburger
    onClick={props.onClick}
    aria-expanded={props.isActive}
    aria-controls="hamburger-menu"
    aria-label={props.isActive ? 'Close menu' : 'Open menu'}>
    <IconHamburger isActive={props.isActive} />
  </S.Hamburger>
);

Hamburger.defaultProps = defaultProps;

export default Hamburger;
