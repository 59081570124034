import * as React from 'react';
import * as S from './styles';

export interface IProps {
  isActive?: boolean;
}

const defaultProps = {
  isActive: false
};

const Hamburger = (props: IProps) => (
  <S.HamburgerIcon isActive={props.isActive}>
    <span className="top" />
    <span className="middle" />
    <span className="bottom" />
  </S.HamburgerIcon>
);

Hamburger.defaultProps = defaultProps;

export default Hamburger;
