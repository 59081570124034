import styled from 'styled-components';
import * as T from '../Typography';

interface IProps {
  display?: string;
  color?: string;
}

/* stylelint-disable-next-line */
export const CTA = styled.div`
  display: ${(props: IProps) => props.display};
`;

export const Link = styled.div`
  /* stylelint-disable */
  & .link {
    color: ${(props: IProps) => props.color};
  }
  /* stylelint-enable */
`;

export const Button = styled(T.Label)`
  /* stylelint-disable */
  & .button {
    color: ${(props: IProps) => props.color};
    background-color: transparent;
    border: 1px solid ${(props: IProps) => props.color};
    font: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    padding: var(--x1-space) var(--x2-space);
    transition: all .125s linear;

    &:hover {
      color: ${(props: IProps) => (props.color === 'var(--white)') ? `var(--black)` : `var(--white)`};
      border-color: ${(props: IProps) => (props.color === 'var(--white)') ? `var(--yellow)` : `var(--black)`};
      background-color: ${(props: IProps) => (props.color === 'var(--white)') ? `var(--yellow)` : `var(--black)`};
    }
  }
  /* stylelint-enable */
`;
