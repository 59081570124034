import * as React from 'react';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  color: 'var(--white)',
  width: '8px',
  height: '16px'
};

const Facebook = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.89469 15.8877V7.99898H7.07233L7.36092 5.28047H4.89469L4.89839 3.91983C4.89839 3.2108 4.96576 2.83088 5.98413 2.83088H7.3455V0.112061H5.16755C2.55148 0.112061 1.63069 1.43083 1.63069 3.64859V5.28077H0V7.99929H1.63069V15.8877H4.89469Z" fill={props.color} />
  </svg>
);

Facebook.defaultProps = defaultProps;

export default Facebook;
