import { useEffect, useState } from 'react';

export const useMousePosition = active => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = e => {
      if (active) {
        setPosition({ x: e.clientX, y: e.clientY });
      }
    };
    window.addEventListener('mousemove', setFromEvent);

    return () => {
      window.removeEventListener('mousemove', setFromEvent);
    };
  }, [active]);

  return position;
};
