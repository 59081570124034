import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import ReactMarkdown from 'react-markdown';
import Nav from '../Nav';
import Address from '../../containers/Address';
import Social from '../../containers/Social';
import * as T  from '../Typography';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  strapline: string;
  footerLinks: Array<{
    text: string;
    items: Array<{
      text: string;
      url: string;
    }>;
  }>;
  copyright: string;
  legalLinks: Array<{
    text: string;
    url: string;
  }>;
}

const Footer = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Footer>
      <footer>
        <Grid columns={24}>
          <Cell width={22} left={2}>
            <S.Row>
              <Grid columns={12}>
                <Cell width={isMobile ? 12 : 6} left={1}>
                  <S.Strapline as={isMobile ? T.H6 : T.H4}>
                    <ReactMarkdown source={props.strapline} escapeHtml={false} renderers={{root: 'h4'}} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
                  </S.Strapline>
                </Cell>
                <Cell width={isMobile ? 12 : 5} left={(isMobile) ? 1 : 8}>
                  <Grid columns={12}>
                    {props.footerLinks.map((item, index) => (
                      <Cell
                        key={index}
                        width={isMobile ? 12 : 6}
                        left={isMobile ? 1 : (6 * index) + 1}>
                        <S.NavigationHeading>{item.text}</S.NavigationHeading>
                        <S.FooterNavigation role="navigation">
                          <Nav links={item.items} color="var(--grey)" />
                        </S.FooterNavigation>
                      </Cell>
                    ))}
                  </Grid>
                </Cell>
              </Grid>
            </S.Row>
            <S.Row>
              <Grid columns={12}>
                <Cell width={isMobile ? 12 : 6} left={1}>
                  <S.Address>
                    <Address />
                  </S.Address>
                  <S.Social>
                    <Social />
                  </S.Social>
                </Cell>
                <Cell width={isMobile ? 12 : 6} left={isMobile ? 1 : 7}>
                  <S.Flex>
                    <S.Box>
                      <S.Copyright>
                        <p>{props.copyright}</p>
                      </S.Copyright>
                      <S.LegalNavigation role="navigation">
                        <Nav links={props.legalLinks} color="var(--grey)" />
                      </S.LegalNavigation>
                    </S.Box>
                  </S.Flex>
                </Cell>
              </Grid>
            </S.Row>
          </Cell>
        </Grid>
      </footer>
    </S.Footer>
  );
};

export default withBreakpoints(Footer);
