import * as React from 'react'
import { Grid, Cell } from 'styled-css-grid'
import CTA from '../CTA'
import Logo from '../Logo'
import Nav from '../Nav'
import Hamburger from '../Hamburger'
import HamburgerMenu from '../../containers/HamburgerMenu'
import * as S from './styles'

export interface IProps {
  title?: string;
  menuLinks: Array<{
    text: string;
    url: string;
    primary?: boolean;
  }>;
}

const Banner = (props: IProps) => {
  const [menuActive, setMenuActive] = React.useState(false)
  const [isSticky, setSticky] = React.useState(false)
  const [pageYOffset, setPageYOffset] = React.useState(0)
  const toggleMenuActive = () => {
    if (menuActive) {
      document.body.classList.remove('fixed')
      window.scrollTo(0, pageYOffset)
      document.documentElement.classList.remove('no-scroll')
    }

    setMenuActive(!menuActive)

    if (!menuActive) {
      setTimeout(() => {
        setPageYOffset(window.pageYOffset)
        document.documentElement.classList.add('no-scroll')
        document.body.classList.add('fixed')
      }, 500) // Start after transition
    }
  }

  if (typeof window !== `undefined`) {
    window.onscroll = () => {
      if (window.pageYOffset >= 1) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
  }

  return (
    <S.Banner>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <S.Navbar isSticky={isSticky}>
            <S.Logo>
              <CTA
                type="link"
                to="/"
                title={props.title}
                ariaLabel="Zero 1">
                <Logo isNegative={false} />
              </CTA>
            </S.Logo>
            <S.Navigation role="navigation">
              <Nav
                links={props.menuLinks.filter(link => link.primary)}
                color="var(--white)" />
            </S.Navigation>
            <S.Hamburger>
              <Hamburger
                isActive={menuActive}
                onClick={toggleMenuActive} />
            </S.Hamburger>
            <S.HamburgerMenu>
              <HamburgerMenu
                menuLinks={props.menuLinks}
                isActive={menuActive}
                onClick={toggleMenuActive} />
            </S.HamburgerMenu>
          </S.Navbar>
        </Cell>
      </Grid>
    </S.Banner>
  )
}

export default Banner
