import styled from 'styled-components';

interface IProps {
  isActive?: boolean;
}

export const HamburgerIcon = styled.div`
  display: inline-block;
  position: relative;
  height: 18px;

  /* stylelint-disable */
  .top,
  .middle,
  .bottom {
    background: ${(props: IProps) =>
      props.isActive ? `var(--black)` : `var(--white)`};
    content: '';
    display: block;
    height: 2px;
    position: relative;
    top: 0;
    transform: ${(props: IProps) =>
      props.isActive ? `rotate(-45deg)` : `rotate(0)`};
    transform-origin: top right;
    transition: all 0.19s;
    width: ${(props: IProps) => (props.isActive ? `25px` : `24px`)};
  }

  .middle {
    left: 0;
    opacity: ${(props: IProps) => (props.isActive ? `0` : `1`)};
    position: absolute;
    top: 8px;
  }

  .bottom {
    bottom: -1px;
    left: ${(props: IProps) =>
      props.isActive ? `0` : `50%`};
    position: absolute;
    top: auto;
    transform: ${(props: IProps) =>
      props.isActive ? `rotate(45deg)` : `rotate(0)`};
    transform-origin: bottom right;
    width: ${(props: IProps) =>
      props.isActive ? `autp` : `50%`};
  }
  /* stylelint-enable */
`;
