import styled from 'styled-components';

export const Hamburger = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: var(--white);
  display: flex;
  justify-content: flex-end;
  outline: none;
  padding: 0;
  width: 5rem;
  height: 5rem;
  z-index: 3;
  /* stylelint-disable-next-line */
  -webkit-tap-highlight-color: transparent; /* for remove highlight */

  &::-moz-focus-inner {
    border: 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  & .sr-only {
    visibility: hidden;
    width: 0;
  }
`;
