import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import IconLeftArrow from '../Icons/LeftArrow';
import IconRightArrow from '../Icons/RightArrow';
import * as T from '../Typography';
import * as S from './styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  backgroundColor?: string;
  label: string;
  headline: string;
  slides: Array<{
    image: any;
    caption: string;
  }>;
}

const NextArrow = props => {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <IconLeftArrow />
    </div>
  );
};

const PrevArrow = props => {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <IconRightArrow />
    </div>
  );
};

const slickSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
  centerMode: true,
  centerPadding: 0,
  slidesToShow: 1,
  variableWidth: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        adaptiveHeight: true
      }
    }
  ]
};

function renderCarouselItems(slide, i) {
  return (
    <div key={i}>
      <S.Figure>
        <figure>
          <Img
            backgroundColor="var(--grey)"
            className="image"
            fluid={slide.image && slide.image.childImageSharp.fluid}
          />
          <S.FigCaption>
            <figcaption>
              <ReactMarkdown source={slide.caption} />
            </figcaption>
          </S.FigCaption>
        </figure>
      </S.Figure>
    </div>
  );
}

const Carousel = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    if (!isClient) {
      setIsClient(true);
    }
  });

  return (
    <S.Carousel backgroundColor={props.backgroundColor}>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <S.Heading>
            <S.Label hasLine={true}>{props.label}</S.Label>
            <S.Headline as={isMobile ? T.H5 : T.H4}>
              <ReactMarkdown source={props.headline} renderers={{root: 'h3'}} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
            </S.Headline>
          </S.Heading>
        </Cell>
      </Grid>
      <Grid columns={24}>
        <Cell width={isMobile ? 22 : 24} left={isMobile ? 2 : 1}>
          <S.Slider>
            {isClient && (
              <Slider {...slickSettings}>
                {props.slides.map(renderCarouselItems)}
              </Slider>
            )}
            {!isClient && <div>{props.slides.map(renderCarouselItems)}</div>}
          </S.Slider>
        </Cell>
      </Grid>
    </S.Carousel>
  );
};

export default withBreakpoints(Carousel);
