import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import {
  Accordion as AccessibleAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import ReactMarkdown from 'react-markdown';
import * as T from '../Typography';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  label: string;
  questions: [];
}

const Accordion = props => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Accordion>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <Grid columns={12}>
            <Cell width={isMobile ? 12 : 8} left={isMobile ? 1 : 3}>
              <S.Wrapper>
                <S.Label hasLine={true}>{props.label}</S.Label>
                <S.AccessibleAccordion>
                  <AccessibleAccordion allowZeroExpanded={true}>
                    {props.questions &&
                      props.questions.map((question, index) => (
                        <AccordionItem key={index}>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <S.Title as={isMobile ? T.LargeBodyCopy : T.H4}>
                                <h3>{question.title}</h3>
                              </S.Title>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <S.Body>
                              <ReactMarkdown source={question.body} />
                            </S.Body>
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                  </AccessibleAccordion>
                </S.AccessibleAccordion>
              </S.Wrapper>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </S.Accordion>
  );
};

export default withBreakpoints(Accordion);
