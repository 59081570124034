import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import BackgroundImage from 'gatsby-background-image';
import CTA from '../CTA';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  boxes2: Array<{
    image: any;
    headline: string;
    button: {
      label: string;
      url: string;
    };
  }>;
  index: number;
}

const Box = props => {
  const [hover, setHover] = React.useState(false);

  return (
    <S.BackgroundImage index={props.index}>
      <BackgroundImage
        Tag="div"
        fluid={props.image && props.image.childImageSharp.fluid}
        className="backgroundImage"
        backgroundColor="var(--grey)"
        classId=""
      >
        <S.Box
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <S.Headline color={hover ? 'var(--black)' : 'var(--white)'}>
            <h3>
              {props.headline}
            </h3>
          </S.Headline>
          <S.Button>
            <CTA
              type="button"
              to={props.button.url}
              title={props.button.label}
              color={hover ? 'var(--black)' : 'var(--white)'}
            >
              {props.button.label}
            </CTA>
          </S.Button>
        </S.Box>
      </BackgroundImage>
    </S.BackgroundImage>
  );
};

const BoxImage = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.BoxImage>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <Grid columns={12}>
            <Cell width={isMobile ? 12 : 6} left={1}>
              <Box {...props.boxes2[0]} index={0} />
            </Cell>
            <Cell width={isMobile ? 12 : 6} left={isMobile ? 1 : 7}>
              <Box {...props.boxes2[1]} index={1} />
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </S.BoxImage>
  );
};

export default withBreakpoints(BoxImage);
