import styled from 'styled-components';
import { Section } from '../Layout/styles';
import * as T from '../Typography';

export const Accordion = styled(Section)`
  border-bottom: 1px solid var(--borderGrey);

  /* stylelint-disable */
  & .accordion__item {
    border-bottom: 1px solid var(--borderGrey);

    &:first-child {
      border-top: 1px solid var(--borderGrey);
    }
  }

  & .accordion__button {
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    padding: calc(var(--x1-space) * 1.5) 0 var(--x2-space);
    position: relative;
    outline: none;
    user-select: none;
  }

  & .accordion__button:before {
    display: inline-block;
    content: '';
    height: 15px;
    width: 15px;
    margin-right: var(--x1-space);
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    transform: rotate(45deg);
    transition: transform 0.35s;
    position: absolute;
    right: 0;
    top: var(--x2-space);
  }

  & .accordion__button[aria-expanded='true']::before,
  & .accordion__button[aria-selected='true']::before {
    transform: rotate(225deg);
  }

  & .accordion__panel {
    padding-bottom: var(--x2-space);
    animation: fadein 0.35s ease-in;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  /* stylelint-enable */
`;

export const Wrapper = styled.div`
  @media (min-width: ${props => props.theme.desktopBreak}) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

export const AccessibleAccordion = styled.div`
  margin-top: var(--x4-space);
`;

export const Label = styled(T.Label)``;

export const Question = styled.div`
  margin-top: var(--x1-space);
  border-bottom: 1px solid var(--borderGrey);
`;

export const Title = styled.div`
  padding-right: var(--x3-space);
`;

export const Body = styled(T.SmallBodyCopy)`
  color: var(--grey);
`;
