import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import CTA from '../CTA';
import * as T from '../Typography';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  label: string;
  address: string;
}

const defaultProps = {
  address: 'hello@zero-1.co'
};

const Email = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Email>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <Grid columns={12}>
            <Cell width={isMobile ? 11 : 9} left={isMobile ? 1 : 3}>
              <S.Label hasLine={true}>{props.label}</S.Label>
              <S.Address as={isMobile ? T.H4 : T.H1}>
                <CTA
                  type="link"
                  to={`mailto:${props.address}`}
                  title={props.address}
                >
                  {props.address}
                </CTA>
              </S.Address>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </S.Email>
  );
};

Email.defaultProps = defaultProps;

export default withBreakpoints(Email);
