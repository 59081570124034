import styled from 'styled-components';
import { Section } from '../Layout/styles';
import * as T from '../Typography';

/* stylelint-disable-next-line */
export const Media = styled(Section)``;

export const OffsetWrapper = styled.div`
  margin-top: var(--x4-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: 75%;
  }
`;

export const Label = styled(T.Label)``;

export const Headline = styled.div`
  margin-top: var(--x1-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    /* stylelint-disable */
    && {
      font-size: 5vw;
      line-height: 5.5vw;
    }
    /* stylelint-enable */
  }
`;

export const Strapline = styled.div`
  margin-top: var(--x1-space);
`;

export const Text = styled(T.SmallBodyCopy)`
  color: var(--grey);
  margin-top: var(--x1-space);

  & a {
    color: var(--white);

    &:hover {
      color: var(--yellow);
    }
  }

  & p {
    margin-bottom: var(--x1-space);
  }

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: var(--x2-space);
  }
`;
