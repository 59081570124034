import * as React from 'react'
import { Waypoint } from 'react-waypoint'
import BlockRevealAnimation from 'react-block-reveal-animation'
import * as S from './styles'

export interface IProps {
  children?: any;
  color: string;
}

const defaultProps = {
  children: null,
  color: '#E8FF00'
}

const RevealingImage = (props: IProps) => {
  const [blockAnim, setBlockAnim] = React.useState(true)

  return (
    <Waypoint onEnter={() => setBlockAnim(false)}>
      <S.RevealingImage blockAnim={blockAnim}>
        <BlockRevealAnimation color={props.color} delay={.5} duration={.5}>
          {props.children}
        </BlockRevealAnimation>
      </S.RevealingImage>
    </Waypoint>
  )
}

RevealingImage.defaultProps = defaultProps

export default RevealingImage
