import styled from 'styled-components';
import { Section }  from '../Layout/styles';
import * as T from '../Typography';

interface IProps {
  color: string;
  index: number;
}

/* stylelint-disable-next-line */
export const BoxImage = styled(Section)``;

export const BackgroundImage = styled.div`
  ${(props: IProps) =>
    (props.index) ? `margin-top: var(--x1-space);` : ``}

  @media (min-width: ${props => props.theme.tabletBreak}) {
    max-width: 400px;
    ${(props: IProps) =>
      (props.index) ? `margin: 0 0 0 var(--xhalf-space);` : `margin: 0 var(--xhalf-space) 0 0; float: right;`}
  }
`;

export const Box = styled.div`
  padding: var(--x2-space) var(--x1-space);
  background-color: transparent;
  transition: background-color .125s linear;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    padding: var(--x4-space) var(--x2-space);

    &:hover {
      background-color: var(--yellow);
    }
  }
`;

export const Headline = styled(T.H6)`
  color: ${(props: IProps) => props.color};
  text-align: center;
`;

export const Button = styled.div`
  margin-top: var(--x2-space);
  text-align: center;
  white-space: nowrap;
`;
