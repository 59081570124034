import styled from 'styled-components'

interface IProps {
  hasLine?: boolean;
  color?: boolean;
}

export const H1 = styled.div`
  font: 400 var(--type-0) var(--font-mark-pro-medium);
  letter-spacing: -3px;
`

export const H2 = styled.div`
  font: 400 var(--type-1) var(--font-mark-pro-medium);
  letter-spacing: -3px;
`

export const H3 = styled.div`
  font: 400 var(--type-2) var(--font-mark-pro-book);
  letter-spacing: -2px;
`

export const H4 = styled.div`
  font: 400 var(--type-3) var(--font-mark-pro-book);
  letter-spacing: -2px;
`

export const H5 = styled.div`
  font: 400 var(--type-4) var(--font-mark-pro-book);
  letter-spacing: -1px;
`

export const H6 = styled.div`
  font: 400 var(--type-5) var(--font-mark-pro-bold);
  letter-spacing: -1px;
`

export const Nav = styled.div`
  font: 400 var(--type-6) var(--font-mark-pro-black);
  letter-spacing: 2px;
`

export const Label = styled.div`
  font: 400 var(--type-7) var(--font-mark-pro-book);
  letter-spacing: 3px;
  text-transform: uppercase;
  color: ${(props: IProps) => (props.color) ? `${props.color}` : `var(--white)`};

  /* stylelint-disable */
  ${(props: IProps) => (props.hasLine) && `
    position: relative;

    &::before {
      content: "";
      width: 43px;
      height: 1px;
      background-color: ${(props.color) ? `${props.color}` : `var(--white)`};
      display: inline-block;
      vertical-align: middle;
      margin-right: var(--x1-space);
      position: relative;
      top: -1px;
    }
  `}
  /* stylelint-enable */
`

export const HugeBodyCopy = styled.div`
  font: 400 var(--type-8) var(--font-mark-pro-book);
  letter-spacing: -1px;
`;

export const LargeBodyCopy = styled.div`
  font: 400 var(--type-9) var(--font-mark-pro-book);
  letter-spacing: 0;
`

export const SmallBodyCopy = styled.div`
  font: 400 var(--type-10) var(--font-mark-pro-book);
  letter-spacing: 0;
  color: var(--bodyCopyGrey);
`

export const PetiteBodyCopy = styled.div`
  font: 400 var(--type-11) var(--font-mark-pro-book);
  letter-spacing: 0;
`

export const TinyBodyCopy = styled.div`
  font: 400 var(--type-12) var(--font-mark-pro-book);
  letter-spacing: 0;
`
