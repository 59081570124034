import { createGlobalStyle } from 'styled-components';

export const GlobalTheme = {
  mobileBreak: '320px',
  desktopBreak: '1024px',
  tabletBreak: '768px'
};

export const GlobalBreakpoints = {
  mobile: 320,
  tablet: 768,
  desktop: 1200
};

export const GlobalStyle = createGlobalStyle`
  /* stylelint-disable */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    &::selection {
      background-color: var(--yellow);
    }
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    scroll-behavior: smooth;

    &.no-scroll {
      scroll-behavior: auto;
    }
  }

  /*
  * Shared CSS custom properties
  */

  :root {
    /*  Type scale
    *
    *  Type and verticle spacing adheres to an 8px baseline grid
    *  We are using a 1rem = 16px baseline for setting font-size and line-height
    *
    *  Baseline body copy font is 16px/24px = 1rem/1.5rem
    *
    *  Usage:
    *  font: 400 var(--type-0) var(--font-sans);
    *
    *  or,
    *  font-size: 1rem;
    */
    --type-0: 6rem/6.5rem; /* Headline 1 */
    --type-1: 4.5rem/4.5rem; /* Headilne 2 */
    --type-2: 3rem/3.5rem; /* Headline 3 */
    --type-3: 2.25rem/2.75rem; /* Headline 4 */
    --type-4: 1.75rem/2.25rem; /* Headline 5 */
    --type-5: 1.75rem/2.25rem; /* Headline 6 */
    --type-6: .875rem/1.125rem; /* Nav */
    --type-7: .75rem/1.25rem; /* Label */
    --type-8: 1.5rem/1.5rem; /* Huge body copy */
    --type-9: 1.125rem/1.75rem; /* Large body copy */
    --type-10: 1rem/1.5rem; /* Small body copy */
    --type-11: .875rem/1.25rem; /* Petite body copy */
    --type-12: .75rem/1.25rem; /* Tiny body copy */

    /* Font Families & Weights */
    --font-mark-pro-black: 'Mark Pro Black', sans-serif;
    --font-mark-pro-bold: 'Mark Pro Bold', sans-serif;
    --font-mark-pro-book: 'Mark Pro Book', sans-serif;
    --font-mark-pro-medium: 'Mark Pro Medium', sans-serif;

    /* Ease functions */
    --ease-inOut: cubic-bezier(0.645, 0.045, 0.355, 1);
    --ease-outQuint: cubic-bezier(0.23, 1, 0.32, 1);

    /* Colors */
    --black: #000000;
    --white: #FFFFFF;
    --yellow: #E8FF00;
    --grey: #999999;
    --darkGrey: #121212;
    --borderGrey: #333333;
    --bodyCopyGrey: #CDCDCD;
    --bodyCopyOnYellow: #2E3300;

    /* Spacers */
    --xhalf-space: calc(var(--x1-space) / 2);
    --x1-space: 1rem;
    --x2-space: calc(var(--x1-space) * 2);
    --x3-space: calc(var(--x1-space) * 3);
    --x4-space: calc(var(--x1-space) * 4);
    --x5-space: calc(var(--x1-space) * 5);
    --x6-space: calc(var(--x1-space) * 6);
    --x7-space: calc(var(--x1-space) * 7);
    --x8-space: calc(var(--x1-space) * 8);
    --x9-space: calc(var(--x1-space) * 9);
    --x10-space: calc(var(--x1-space) * 10);
  }

  /**
  * Spacing and type sizes can be proportionally scaled by:
  * - using the rem unit within components
  * - specifying a percentage value for the font - size property on the html selector
  */

  html, body {
    color: var(--white);
    background-color: var(--black);
    font-size: 100%;
    font-family: var(--font-mark-pro-book);
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.fixed {
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: fixed;
    }
  }

  button {
    cursor: pointer;
  }

  a {
    color: var(--yellow);
  }

  @media (min-width: 1024px) {
    html, body {
      font-size: 100%; /* 16px base font */
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
  /* stylelint-enable */
`;
