import * as React from 'react';
import BlockRevealAnimation from 'react-block-reveal-animation';
import * as S from './styles';

export interface IProps {
  text: string;
  color: string;
  blockAnim: boolean;
}

const defaultProps = {
  text: '',
  color: '#E8FF00',
  blockAnim: false
};

const RevealingText = (props: IProps) => {
  return (
    <S.RevealingText blockAnim={props.blockAnim}>
      {props.text &&
        props.text.split('\\').map((part, index) => (
          <div key={index}>
            <BlockRevealAnimation
              color={props.color}
              delay={index * 0.5}
              duration={0.5}>
              {part}
            </BlockRevealAnimation>
          </div>
        ))}
    </S.RevealingText>
  );
};

RevealingText.defaultProps = defaultProps;

export default RevealingText;
