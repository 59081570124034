import styled from 'styled-components';
import { Cell } from 'styled-css-grid';
import * as T from '../Typography';

interface IProps {
  color: string;
  isActive?: boolean;
}

export const HamburgerMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  background-color: var(--yellow);
  transition: transform 0.5s;
  ${(props: IProps) =>
    props.isActive
      ? `transform: translateX(0%);`
      : `transform: translateX(-100%);`}
  /* stylelint-disable */
  *::selection {
    background-color: var(--grey);
  }
  /* stylelint-enable */
`;

export const RCell = styled(Cell)`
  position: relative;
`;

export const Lens = styled.div.attrs({
  style: props => ({
    transform: `translate(calc(${props.position.x}px - 180px - 50vw), calc(${
      props.position.y
    }px - 180px - 50vh))`
  })
})`
  position: absolute;
  background: radial-gradient(var(--white) 0%, var(--yellow) 75%);
  border-radius: 50%;
  width: 360px;
  height: 360px;
  top: 50%;
  right: -180px;
  transform: translate(0, -50%);
  pointer-events: none;
  will-change: transform;

  /* stylelint-disable */
  @media (max-width: 767px) {
    transform: translate(0, -50%) !important;
  }
  /* stylelint-enable */

  /* stylelint-disable */
  @media (max-width: 1023px) {
    transform: translate(-50%, -50%) !important;
  }
  /* stylelint-enable */

  @media (min-width: ${props => props.theme.tabletBreak}) {
    top: 50%;
    right: auto;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  height: var(--x5-space); /* Because no button */
  box-sizing: content-box;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    height: var(--x7-space); /* Because no button */
    display: flex;
    align-items: center;
  }
`;

export const Logo = styled.div`
  position: relative;

  & *[aria-hidden] {
    font-size: 0;
    overflow: hidden;
    visibility: hidden;
    position: absolute;
  }
`;

export const HamburgerNavigation = styled.nav`
  /* stylelint-disable */
  ul {
    text-align: left;
    list-style: none;
    margin: 0;
    position: absolute;
    top: 100px;
    left: var(--single-space);
    overflow: hidden;

    @media (min-width: ${props => props.theme.tabletBreak}) {
      text-align: right;
      top: 50%;
      right: 10%;
      left: auto;
      transform: translateY(-50%);
    }
  }

  li {
    display: block;

    .link {
      display: inline-block;
      text-decoration: none;
      white-space: nowrap;

      @media (min-width: ${props => props.theme.tabletBreak}) {
        transform: translateX(127px); /* 107px + 20px */
        transition: transform 0.5s;

        &::after {
          display: inline-block;
          background-color: var(--black);
          content: '';
          height: 8px;
          width: 107px;
          margin-left: 20px;
          position: relative;
          vertical-align: middle;
        }

        &:hover {
          transform: translateX(0);
        }
      }
    }

    &.primary {
      .link {
        font: 400 var(--type-2) var(--font-mark-pro-book);
        letter-spacing: -2px;

        @media (min-width: ${props => props.theme.tabletBreak}) {
          font: 400 var(--type-0) var(--font-mark-pro-book);
          letter-spacing: -3px;
        }
      }
    }

    &.secondary {
      font: 400 var(--type-3) var(--font-mark-pro-book);
      letter-spacing: -1px;

      @media (min-width: ${props => props.theme.tabletBreak}) {
        font: 400 var(--type-2) var(--font-mark-pro-book);
        letter-spacing: -2px;

        .link {
          @media (min-width: ${props => props.theme.tabletBreak}) {
            &::after {
              height: 4px;
            }
          }
        }
      }
    }
  }

  li.primary + li.secondary {
    margin-top: 50px;
  }
  /* stylelint-enable */
`;

export const ContactItems = styled.ul`
  position: absolute;
  left: 0;
  bottom: var(--x3-space);
`;

export const ContactItem = styled.li`
  list-style: none;
`;

export const Address = styled.div`
  display: block;

  /* stylelint-disable */
  @media (max-width: ${props => props.theme.mobileBreak}) {
    display: none;
  }
  /* stylelint-enable */
`;

export const Email = styled(T.H4)`
  /* stylelint-disable */
  a {
    color: ${(props: IProps) => props.color};
  }
  /* stylelint-enable */
`;
