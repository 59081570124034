import * as React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

export interface IProps {
  title?: string;
  description?: string;
}

class Head extends React.Component<any, any> {
  public static defaultProps = {
    lang: 'en',
    title: 'Zero 1 - We are a Venture Building Studio',
    description:
      'Zero 1 is a venture building studio that builds new companies in partnership with leading corporations and entrepreneurs.',
    meta: [{ name: 'HandheldFriendly', content: 'true' }]
  };
  public render() {
    const { lang, title, description, meta, data, site } = this.props;
    const metaTag = data.meta || meta;
    return (
      <Helmet
        htmlAttributes={{ lang }}
        title={title || data.title}
        defaultTitle={title || data.title}
        meta={([
          {
            content: description || data.description,
            name: `description`
          },
          {
            content: data.keywords,
            name: `keywords`
          },
          {
            content: title || data.title,
            property: `og:title`
          },
          {
            content: `website`,
            property: `og:type`
          },
          {
            content: data.logo,
            property: `og:image`
          },
          {
            content: `summary_large_image`,
            name: `twitter:card`
          },
          {
            content: title || data.title,
            name: `twitter:title`
          },
          {
            content: description || data.description,
            name: `twitter:description`
          },
          {
            content: data.keywords,
            name: `keywords`
          }
        ] as any[]).concat(metaTag)}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'Organization',
            name: data.author,
            url: site.siteUrl,
            email: data.emailAddress,
            logo: data.logo,
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': site.siteUrl
            },

            address: {
              '@type': 'PostalAddress',
              streetAddress: data.streetAddress,
              addressLocality: data.addressLocality,
              postalCode: data.postalCode
            },
            sameAs: [data.linkedInUrl]
          })}
        </script>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="bbf289e9-17f8-4c6e-8b61-529a3a2d4003"
          type="text/javascript"
          async={true}
        />
      </Helmet>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query SiteHeadQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
        global: markdownRemark(frontmatter: { templateKey: { eq: "global" } }) {
          frontmatter {
            title
            description
            keywords
            logo
            author
            streetAddress
            addressLocality
            emailAddress
            postalCode
            linkedInUrl
            meta {
              content
              name
            }
          }
        }
      }
    `}
    render={data => (
      <Head
        site={data.site.siteMetadata}
        data={data.global.frontmatter}
        {...props}
      />
    )}
  />
);
