import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import * as T from '../Typography';
import * as S from './styles';
import ReactMarkdown from 'react-markdown';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  overlappedBottom?: boolean;
  overlappedTop?: boolean;
  backgroundColor?: string;
  color?: string;
  label: string;
  headline: string;
  text1: string;
  text2: string;
  hasLine?: boolean;
}

const defaultProps = {
  color: 'var(--white)',
  backgroundColor: 'var(--black)',
  hasLine: false
};

const Column = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Column
      backgroundColor={props.backgroundColor}
      overlappedBottom={props.overlappedBottom}
      overlappedTop={props.overlappedTop}
    >
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <Grid columns={12}>
            <Cell width={11} left={isMobile ? 1 : 2}>
              {props.label && (
                <S.Label color={props.color} hasLine={true}>
                  {props.label}
                </S.Label>
              )}
              <S.Headline as={isMobile ? T.H4 : T.H2} color={props.color}>
                <ReactMarkdown source={props.headline} escapeHtml={false} renderers={{root: 'h2'}} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
              </S.Headline>
            </Cell>
          </Grid>
        </Cell>
        <Cell width={22} left={2}>
          <S.Columns>
            <Grid columns={12}>
              <Cell
                width={isMobile ? 11 : props.hasLine ? 5 : 3}
                left={isMobile ? 1 : props.hasLine ? 2 : 4}
              >
                <S.Text color={props.color} hasLine={props.hasLine}>
                  <ReactMarkdown source={props.text1} escapeHtml={false} />
                </S.Text>
              </Cell>
              <Cell width={isMobile ? 11 : 3} left={isMobile ? 1 : 8}>
                <S.Text color={props.color}>
                  <ReactMarkdown source={props.text2} escapeHtml={false} />
                </S.Text>
              </Cell>
            </Grid>
          </S.Columns>
        </Cell>
      </Grid>
    </S.Column>
  );
};

Column.defaultProps = defaultProps;

export default withBreakpoints(Column);
