import * as React from 'react';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  color: 'var(--white)',
  width: '41px',
  height: '76px'
};

const RightArrow = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 42 76" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Redesign" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Homepage" transform="translate(-1311.000000, -3960.000000)" fillRule="nonzero" stroke={props.color} strokeWidth="3">
        <polyline id="Path-5" points="1313 3961 1350 3998 1313 4035" />
      </g>
    </g>
  </svg>
);

RightArrow.defaultProps = defaultProps;

export default RightArrow;
