import * as React from 'react'
import { IconLinkedIn, IconTwitter, IconInstagram, IconCrunchbase } from '../Icons'
import * as S from './styles'

export interface IProps {
  color?: string;
  size?: string;
  linkedInUrl?: string;
  twitterUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  crunchbaseUrl?: string;
}

const defaultProps = {
  color: 'var(--white)',
  size: '50px'
}

const Social = (props: IProps) => {
  return (
    <S.Social>
      <S.SocialItems>
        {(props.linkedInUrl) &&
          <S.SocialItem>
            <S.SocialLink
              href={props.linkedInUrl}
              target="_blank"
              color={props.color}
              size={props.size}
              aria-label="LinkedIn"
              rel="noopener noreferrer">
              <IconLinkedIn color={props.color} />
            </S.SocialLink>
          </S.SocialItem>}
        {(props.twitterUrl) &&
          <S.SocialItem>
            <S.SocialLink
              href={props.twitterUrl}
              target="_blank"
              color={props.color}
              size={props.size}
              aria-label="Twitter"
              rel="noopener noreferrer">
              <IconTwitter color={props.color} />
            </S.SocialLink>
          </S.SocialItem>}
        {(props.instagramUrl) &&
          <S.SocialItem>
            <S.SocialLink
              href={props.instagramUrl}
              target="_blank"
              color={props.color}
              size={props.size}
              aria-label="Instagram"
              rel="noopener noreferrer">
              <IconInstagram color={props.color} />
            </S.SocialLink>
          </S.SocialItem>}
        {(props.crunchbaseUrl) &&
          <S.SocialItem>
            <S.SocialLink
              href={props.crunchbaseUrl}
              target="_blank"
              color={props.color}
              size={props.size}
              aria-label="Crunchbase"
              rel="noopener noreferrer">
              <IconCrunchbase color={props.color} />
            </S.SocialLink>
          </S.SocialItem>}
      </S.SocialItems>
    </S.Social>
  )
}

Social.defaultProps = defaultProps

export default Social
