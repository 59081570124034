import styled from 'styled-components';

interface IProps {
  color?: string;
}

export const NavItem = styled.li`
  /* stylelint-disable */
  .link {
    color: ${(props: IProps) => props.color};
  }
  /* stylelint-enable */
`;
