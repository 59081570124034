import * as React from 'react'
import CTA from '../CTA'
import * as S from './styles'

export interface IProps {
  color?: string;
  onClick?: () => any;
  links: Array<{
    text: string;
    url: string;
    primary?: boolean;
  }>;
}

const defaultProps = {
  color: 'var(--white)'
};

const NavLink = (props) => (
  <S.NavItem className={`${props.primary ? 'primary' : 'secondary'}`}>
    <CTA
      type="link"
      to={props.url}
      title={props.text}
      color={props.color}
      onClick={props.onClick}>
      {props.text}
    </CTA>
  </S.NavItem>
)

const Nav = (props: IProps) => {
  return (
    <nav>
      <ul>
        {props.links &&
          props.links.length > 0 &&
          props.links.map((link, index) => (
            <NavLink
              key={index}
              color={props.color}
              onClick={props.onClick}
              {...link} />
          ))}
      </ul>
    </nav>
  )
}

Nav.defaultProps = defaultProps

export default Nav
