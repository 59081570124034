import * as React from 'react';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  color: 'var(--white)',
  width: '16px',
  height: '15px'
};

const LinkedIn = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.69675 1.70538C3.67543 0.737327 2.98317 0 1.85904 0C0.734914 0 0 0.737327 0 1.70538C0 2.65338 0.713193 3.41194 1.81639 3.41194H1.83738C2.98317 3.41194 3.69675 2.65338 3.69675 1.70538ZM3.48033 4.75953H0.19431V14.6327H3.48033V4.75953ZM11.5475 4.52769C13.7099 4.52769 15.331 5.93911 15.331 8.97171L15.3308 14.6327H12.0449V9.35053C12.0449 8.02377 11.5694 7.11843 10.3799 7.11843C9.47206 7.11843 8.93132 7.72876 8.69383 8.31826C8.60695 8.52952 8.58562 8.82387 8.58562 9.11895V14.6329H5.29921C5.29921 14.6329 5.34252 5.68624 5.29921 4.7598H8.58562V6.15824C9.02175 5.48598 9.80286 4.52769 11.5475 4.52769Z" fill={props.color} />
  </svg>
);

LinkedIn.defaultProps = defaultProps;

export default LinkedIn;
