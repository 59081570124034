import * as React from 'react';

export interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  color: 'var(--white)',
  width: '41px',
  height: '76px'
};

const LeftArrow = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 41 76" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Redesign" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Homepage" transform="translate(-87.000000, -3960.000000)" fillRule="nonzero" stroke={props.color} strokeWidth="3">
        <polyline id="Path-5" transform="translate(108.500000, 3998.000000) rotate(-180.000000) translate(-108.500000, -3998.000000) " points="90 3961 127 3998 90 4035" />
      </g>
    </g>
  </svg>
);

LeftArrow.defaultProps = defaultProps;

export default LeftArrow;
